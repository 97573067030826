<template>
        <div class="wrapper">
            <CRow>
                <CCol lg="8">
                    <CForm @submit.prevent="createTemplate">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="paperclip"/>  New Template
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            </CCardHeader>
                            <CCardBody>
                                    <CRow>
                                        <CCol lg="6" md="6" xl="6">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.template.name.$model"
                                                :isValid="checkIfValid('name')"
                                                label="Name" 
                                                type="text" 
                                                placeholder="March-JO Template" 
                                                autocomplete="off"  
                                                v-tocapitalize
                                                v-model="template.name"
                                                invalidFeedback="Must be three(3) characters."
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="6" md="6" xl="6">
                                            <label>Category</label>
                                                <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                                            <v-select 
                                                label="name"
                                                :options="template_category"
                                                v-model="template.category"
                                                :class="!template.category ? 'has-error' : 'has-success'"
                                            />
                                        </CCol>
                                        
                                        <CCol lg="6" md="6" xl="6">
                                            <label>File API</label>
                                                <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                                            <v-select 
                                                label="name"
                                                :options="template_file_api"
                                                v-model="template.file_api"
                                            />
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="2">
                                            Signatory Locked
                                        </CCol>
                                        <CCol lg="10">
                                            <p-check class="p-icon p-bigger" color="success" v-model="template.signatory_lock">
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-check>
                                            
                                            <a-popover title="NOTE:" placement="topRight">
                                                <template slot="content">
                                                <p>Once this has been activated and used in routing, <br>you cannot add, remove, or pull out the signatory in the active routing. </p>
                                                </template>
                                                    <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                            </a-popover>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="12" md="12" xl="12">
                                            <CTextarea
                                                class="grp-text-area"
                                                :lazy="false"
                                                :value.sync="$v.template.description.$model"
                                                :isValid="checkIfValid('description')"
                                                label="Description"
                                                placeholder="Content..."
                                                autocomplete="off"  
                                                v-tocapitalize
                                            />
                                        </CCol>
                                    </CRow>
                            </CCardBody>
                        </CCard>
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="signature"/>  Signatory & Requirements
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol xl="4" lg="4" md="4" sm="12">
                                        <b>Select signatory</b>
                                    </CCol>
                                    <CCol xl="3" lg="3" md="3" sm="12">
                                        <b>Purpose</b>
                                    </CCol>
                                    <CCol xl="1" lg="1" md="2" sm="12">
                                        <b>Order</b>
                                    </CCol>
                                    <CCol xl="3" lg="3" md="3" sm="12">
                                        <b>Signature</b>
                                    </CCol>
                                </CRow>
                                <CRow style="margin-top:10px;">
                                    <CCol xl="4" lg="4" md="4" sm="12">
                                        <CInput 
                                            placeholder="John Doe"
                                            type="text" 
                                            autocomplete="off" 
                                            readonly 
                                            v-model="template_signatory.name"
                                        >
                                            <template #append>
                                                <CButton color="primary"  @click="$refs.usersModal.users_modal = true, emitUserModal(), user_shared_routing = false">
                                                <font-awesome-icon icon="search"/>
                                                </CButton>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol xl="3" lg="3" md="3" sm="12">
                                        <CInput 
                                            type="text" 
                                            autocomplete="off"  
                                            v-tocapitalize
                                            v-model="template_signatory.purpose"
                                        />
                                    </CCol> 
                                    <CCol xl="1" lg="1" md="1" sm="12">
                                        <CInput 
                                            type="text" 
                                            maxlength="2"
                                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'') "
                                            autocomplete="off"  
                                            v-model="template_signatory.order"
                                        />
                                    </CCol> 
                                    <CCol xl="1" lg="1" md="1" sm="12">
                                        <p-check 
                                            class="p-icon p-bigger" 
                                            color="success" 
                                            off-color="danger"
                                            toggle
                                            style="font-size: x-large; margin-top:7px;"
                                            v-model="template_signatory.required_signature"
                                        >
                                            <!-- <font-awesome-icon class="icon"  icon="check"/> -->
                                            
                                            <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                            <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                            
                                            <label slot="off-label"></label>
                                        </p-check>
                                    </CCol> 
                                    <CCol xl="2" lg="2" md="2" sm="6" xs="6">
                                        <CButton 
                                            color="warning" 
                                            @click="openRequirementsModal();"
                                            :disabled="!template_signatory.purpose || !template_signatory.order || !template_signatory.user_id"
                                        >
                                            Requirements
                                        </CButton>
                                    </CCol> 
                                    <CCol xl="1" lg="1" md="1" sm="6" xs="6">
                                        <CButton 
                                            color="primary" 
                                            @click="addSignatory()"
                                            :disabled="!template_signatory.purpose || !template_signatory.order || !template_signatory.user_id"
                                        >
                                            <font-awesome-icon icon="plus"/>
                                        </CButton>
                                    </CCol> 
                                    <CCol lg="12" >
                                        <!-- <template v-if="template.signatory_list.length > 1">
                                            <CButton 
                                                size="sm"
                                                color="danger" 
                                                class="float-right" 
                                                v-show="editOrder"
                                                @click="editOrder = !editOrder"
                                            >
                                                <font-awesome-icon icon="window-close"/> Cancel
                                            </CButton>

                                            <CButton 
                                                size="sm"
                                                color="success" 
                                                class="float-right" 
                                                v-show="editOrder" 
                                                @click="confirmChangeOrder()"
                                            >
                                                <font-awesome-icon icon="save"/> Confirm Changes
                                            </CButton>

                                            <CButton 
                                                size="sm"
                                                color="info" 
                                                class="float-right" 
                                                @click="editOrder = !editOrder" 
                                                v-show="!editOrder"
                                            >
                                                <font-awesome-icon icon="arrows-alt-v"/> Change signature order
                                            </CButton>
                                        </template> -->
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="template.signatory_list"
                                            :fields="signatory_list_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            size="sm"
                                            items-per-page-select
                                            sorter
                                            pagination
                                        >
                                            
                                            <template #name="{item, index}">
                                                <td>
                                                    <v-select 
                                                        :clearable="false"
                                                        @option:selected="selectChangeNameOnList(item, index)"
                                                        label="name"
                                                        :options="user_signatories"
                                                        v-model="item.name"
                                                        :class="!item.name ? 'has-error' : 'has-success'"
                                                    />
                                                </td>
                                            </template>
                                            <template #purpose="{item}">
                                                <td>
                                                    <center>
                                                        <CInput
                                                            type="text" 
                                                            autocomplete="off"  
                                                            :value="item.purpose"
                                                            v-model="item.purpose"
                                                            :class="!item.purpose ? 'has-inp-error' : 'has-inp-success'"
                                                        />
                                                    </center>
                                                </td>
                                            </template>
                                            <template #required_signature="{item}">
                                                <td>
                                                    <center>
                                                        <p-check 
                                                            class="p-icon p-bigger" 
                                                            color="success" 
                                                            off-color="danger"
                                                            toggle
                                                            style="font-size: x-large; "
                                                            v-model="item.required_signature"
                                                        >
                                                            <!-- <font-awesome-icon class="icon"  icon="check"/> -->
                                                            
                                                            <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                            <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                            
                                                            <label slot="off-label"></label>
                                                        </p-check>
                                                    </center>
                                                </td>
                                            </template>
                                            <template #order="{item}">
                                                <td>
                                                    <center>
                                                        <CInput 
                                                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                            style="max-width: 50px;"
                                                            type="text" 
                                                            maxlength="2"
                                                            :value="item.order"
                                                            v-model="item.order"
                                                            :class="!item.order ? 'has-inp-error' : 'has-inp-success'"
                                                        />
                                                    </center>
                                                </td>
                                            </template>
                                            <template #action="{index}">
                                                <td>
                                                    <center>
                                                        <CButton 
                                                            color="danger" 
                                                            @click="template.signatory_list.splice(index, 1)" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="times"/> Remove
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                            
                                            <template #requirements="{item, index}">
                                                <td>
                                                    <center>
                                                        <CButton 
                                                            color="info" 
                                                            @click="viewRequirements(item, index), signatory_requirements_modal = true" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="eye"/> Requirements ( {{item.document_signature_requirements.length}} )
                                                        </CButton>
                                                    </center>
                                                </td>
                                                <!-- <td v-if="item.requirements == false">
                                                    <CButton 
                                                        color="info" 
                                                        @click="addRequirements(item, index), signatory_requirements_modal = true" 
                                                        shape="pill" 
                                                        size="sm"
                                                    >
                                                        <font-awesome-icon icon="eye"/>  Requirements ( {{item.document_signature_requirements.length}} )
                                                    </CButton>
                                                </td> -->
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CButton 
                                            :disabled="!isValid"
                                            class="float-right"
                                            type="submit" 
                                            shape="pill" 
                                            color="primary"
                                        >
                                            <font-awesome-icon icon="save"/> Save
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CForm>
                </CCol>
                <!-- <CCol lg="4">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="share-square"/>  Template Shared Routing
                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol lg="9">
                                    <CInput 
                                        placeholder="John Doe"
                                        type="text" 
                                        autocomplete="off" 
                                        readonly 
                                        v-model="user_shared.name"
                                    >
                                        <template #append>
                                            <CButton color="primary"  @click="userSharedRouting()">
                                                <font-awesome-icon icon="search"/>
                                            </CButton>
                                        </template>
                                    </CInput>
                                </CCol>
                                <CCol lg="3">
                                    <CButton
                                        :disabled="!user_shared.name"
                                        class="float-right"
                                        color="primary"
                                        @click="user_shared.name ? addUserForSharedRouting(): ''"
                                    >
                                    <font-awesome-icon icon="plus"/>
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                            :items="users_shared_routing_list"
                                            :fields="users_shared_routing_list_fields"
                                            :items-per-page="10"
                                            border
                                            sorter
                                        >
                                        <template #action="{item, index}">
                                            <td>
                                                <CButton 
                                                    color="danger" 
                                                    @click="removeUserForSharedRouting(item, index)" 
                                                    shape="pill" 
                                                    size="sm"
                                                >
                                                    <font-awesome-icon icon="times"/>
                                                </CButton>
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow> 
                        </CCardBody>
                    </CCard>
                </CCol> -->
            </CRow>
            <CModal
                :show.sync="signatory_requirements_modal"
                color="primary"
                >
                <template #header>
                    <h6> <font-awesome-icon icon="comment-dots"/> Signatory Requirements for {{template_signatory.name || selected_signatory_details.name}} </h6>
                    <CButton 
                        class="pull-right" 
                        color="light" 
                        shape="pill" 
                        size="sm"
                        @click="signatory_requirements_modal = false"
                    >
                        <font-awesome-icon  size="sm" icon="times"/>
                    </CButton>
                </template>
                <template #footer>
                    <CRow lg="12">
                        <CButton 
                            class="pull-right" 
                            color="primary" 
                            @click="signatory_requirements_modal = false"
                        >
                            Close
                        </CButton>
                    </CRow>
                </template>
                <template #body-wrapper>
                    <CCardBody>
                        <CRow>
                            <CCol xl="10" lg="10" md="10" sm="10">
                                <CInput 
                                    type="text" 
                                    placeholder="Total amount must be greater that 10,000?" 
                                    autocomplete="off"  
                                    v-model="template_signatory_requirements.requirement"
                                    v-tocapitalize
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xl="10" lg="10" md="10" sm="10">
                                <CTextarea
                                    placeholder="Brief description about the requirements..."
                                    autocomplete="off"  
                                    v-model="template_signatory_requirements.description"
                                    v-tocapitalize
                                />
                            </CCol>
                            <CCol xl="2" lg="2" md="2" sm="2">
                                <CButton 
                                    :disabled="!template_signatory_requirements.requirement || !template_signatory_requirements.description"
                                    color="primary" 
                                    @click="addSignatoryRequirements()" >
                                    <font-awesome-icon icon="plus"/>
                                </CButton>
                            </CCol>
                            
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :key="signatory_requirements_key"
                                    :items="signatory_requirements_list"
                                    :fields="signatory_requirements_list_fields"
                                    border
                                    sorter
                                    pagination
                                >
                                    <template #action="{item, index}">
                                        <td>
                                            <center>
                                                <CButton 
                                                    color="danger" 
                                                    @click="removeSignatoryRequirement(item, index)" 
                                                    shape="pill" 
                                                    size="sm"
                                                >
                                                    <font-awesome-icon icon="times"/> Remove
                                                </CButton>
                                            </center>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </template>
            </CModal>
            <UsersModal ref="usersModal" @selectedUser="userSelected($event)"/>
        </div>
</template>
<script>
import UsersModal from '../../modals/UsersModal'
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplateCreate',
    components: {
        UsersModal,
    },
    data() {
        return {
            signatory_requirements_key: 0,

            template_category: [],
            user_shared_routing: false,
            user_shared: {
                name: null,
                user_id: null
            },
            template_file_api: [],

            users_shared_routing_list: [],
            users_shared_routing_list_fields: [
                { key: 'name'},
                { key: 'action' },
            ],

            user_signatories:[],

            order_input:[],

            editOrder: false,

            selected_user_for_signatory: null,

            signatory_requirements_modal: false,

            signatory_requirement_view: false,

            selected_signatory_details: {
                name: null
            }, 

            selected_signatory_details_index: null,

            // Data
            template: {
                name: '',
                description: '',
                signatory_list: [],
                category: null,
                file_api: null,
                signatory_lock: false
            },
            // template:{"name":"Sdfsaf","description":"Sdada","signatory_list":[{"user_id":1,"name":"Super Admin","purpose":"12","order":"1","requirements":false,"document_signature_requirements":[]},{"user_id":5,"name":"Erick Buenaventura","purpose":"2","order":"1","requirements":false,"document_signature_requirements":[]},{"user_id":1,"name":"Super Admin","purpose":"1","order":"1","requirements":false,"document_signature_requirements":[]}],"category":{"id":3,"name":"Reinvoice Routing"},"file_api":null},

            template_signatory: {
                user_id: null,
                name: null,
                purpose: null,
                order: null,
                required_signature: true,
            },

            template_signatory_requirements: {
                requirement: null,
                description: null,
            },

            signatory_list_fields: [
                { key: 'name',},
                { key: 'purpose' },
                { key: 'order' },
                { key: 'required_signature' },
                { key: 'requirements' },
                { key: 'action' },
            ],

            signatory_requirements_list: [],
            signatory_requirements_list_fields: [
                { key: 'requirement',},
                { key: 'description'},
                { key: 'action' },
            ],
        }
    },
    computed: {
        isValid () { return !this.$v.template.$invalid },
        isDirty () { return this.$v.template.$anyDirty },
    },
    validations: {
        template: {
            name: { required, minLength: minLength(3) },
            description: { required },
            category: { required },
            signatory_list: { required, minLength: minLength(1) }
        },
    },
    created() {
        this.$v.$touch()
        this.$emit('activeTab', 1);
        
        this.$Progress.start()
        this.getCategory();
        this.getFileAPI();
        this.getUsers();
        this.$Progress.finish()
    },
    methods: {
        selectChangeNameOnList: function (item, index) {
            let list = this.template.signatory_list;
            list[index].user_id = item.name.user_id.toString();
            list[index].name = item.name.name
        },  
        getUsers:function(){
            axios.post('/users/list-with-parameter/', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.users = []
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            user_id: response.data.data[i].id,
                            name: `${response.data.data[i].first_name} ${response.data.data[i].last_name}${response.data.data[i].suffix ? ' '+response.data.data[i].suffix: ''}`,
                        }
                        this.user_signatories.push(option)
                    }
                }
            })
        },
        openRequirementsModal:function() {
            this.signatory_requirements_key += 1
            this.signatory_requirements_modal = true; 
            this.signatory_requirements_list = []
        },
        getCategory: function() {
            this.template_category = [];
            axios.get('/drs/document-template-category/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].category
                        }

                        this.template_category.push(option)
                    }
                }
            })
        },
        getFileAPI: function (){
            this.template_file_api = [];
            axios.get('/drs/document-template-file-api/list-for-other-modules', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].name
                        }
                        this.template_file_api.push(option)
                    }
                }
            })
        },
        removeUserForSharedRouting: function (item, index) {
            this.users_shared_routing_list.splice(index, 1)
        },
        addUserForSharedRouting: function () {
            if(!this.users_shared_routing_list.filter(e => e.user_id === this.user_shared.user_id).length > 0) {
                let _row = {
                    user_id: this.user_shared.user_id,
                    name: this.user_shared.name
                }

                this.user_shared.user_id = null;
                this.user_shared.name = null;

                this.users_shared_routing_list.push(_row)
            } else {
                 return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `${this.user_shared.name} already exists.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
        },
        userSharedRouting: function () {
            this.user_shared_routing = true;
            this.$refs.usersModal.users_modal = true, 
            this.emitUserModal()
        },
        userSelected: function (object) {
            if(!this.user_shared_routing) {
                this.selected_user_for_signatory = object
            } else {
                this.user_shared_routing= false,
                this.user_shared.name = object.name
                this.user_shared.user_id = object.id
            }
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.template[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateTemplate:function () {
            if( !this.template.name || !this.template.name || this.template.signatory_list.length <= 0 ){
                return false;
            } 
            return true;
        },
        emitUserModal: function () {
            this.$emit('show_users_modal');
        }, 
        // confirmChangeOrder: function () {
        //     let list = this.template.signatory_list;
        //     let order_value = [];
        //     for (let i = 0; i < list.length; i++) {
        //         const input_new_value = this.order_input[i];
        //         if(!input_new_value) {
        //             return this.$swal({
        //                 toast: true,
        //                 position: 'top-right',
        //                 timer: 3000,
        //                 icon: 'error',
        //                 title: `Order field cannot be empty.`,
        //                 showConfirmButton: false,
        //                 timerProgressBar: true,
        //             })
        //         }
        //         order_value.push(input_new_value)
        //     }
        //     // if(order_value.some(x => order_value.indexOf(x) !== order_value.lastIndexOf(x))) {
        //     //     return this.$swal({
        //     //         toast: true,
        //     //         position: 'center-end',
        //     //         showConfirmButton: false,
        //     //         timer: 3000,
        //     //         icon: 'error',
        //     //         title: `Order cannot be duplicate`,
        //     //     })
        //     // } else {        
        //         for (let i = 0; i < list.length; i++) {
        //             const value = this.order_input[i];
        //             list[i].order = value;
        //         }
        //     // }
        //     this.editOrder = false
        //     list.sort(function (x, y) {
        //         return x.order - y.order;
        //     });
        // },
        addSignatoryRequirements: function () {
            let tbl_row = JSON.parse( JSON.stringify( this.signatory_requirements_list ) );
            this.signatory_requirements_list.length = 0;
            if(!this.template_signatory_requirements.requirement || !this.template_signatory_requirements.description) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            let row = {
                requirement: this.template_signatory_requirements.requirement,
                description: this.template_signatory_requirements.description,
            }

            tbl_row.push(row)

            this.signatory_requirements_list = JSON.parse( JSON.stringify( tbl_row ) );
            // this.signatory_requirements_list.push(row)

            if(this.signatory_requirement_view) { 
                this.template.signatory_list[this.selected_signatory_details_index].document_signature_requirements.push(row)
            } 

            this.template_signatory_requirements.requirement = null;
            this.template_signatory_requirements.description = null;

        }, 
        removeSignatoryRequirement: function (item, index) {
            if(this.signatory_requirement_view) {
                this.template.signatory_list[this.selected_signatory_details_index].document_signature_requirements.splice(index, 1)
                if(this.template.signatory_list[this.selected_signatory_details_index].document_signature_requirements.length <= 0) {
                    this.template.signatory_list[this.selected_signatory_details_index].requirements = false;
                }
            }
            this.signatory_requirements_list.splice(index, 1)
        },
        addSignatory: function() {

            for (let i = 0; i < this.template.signatory_list.length; i++) {
                const element = this.template.signatory_list[i];
                if(element.name.name) {
                    let user_name = element.name.name
                    let user_id = element.name.user_id

                    element.name = user_name
                    element.user_id = user_id
                }
            }


            for (let i = 0; i < this.template.signatory_list.length; i++) {
                if(this.template.signatory_list[i].user_id == this.template_signatory.user_id && this.template.signatory_list[i].order == this.template_signatory.order) {
                    return this.$swal({
                        customClass: 'ta-left',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 4000,
                        // icon: 'error',
                        html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>${this.template_signatory.name} exists with the same order number. <br> Changing the order will allow duplicate signatory.</h5>
                                </div>`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                }
            }


            /*
            |--------------------------------------------------------------------------
            | Same order allowed. May 31, 2021 - 
            |--------------------------------------------------------------------------
            */
            // for (let i = 0; i < this.template.signatory_list.length; i++) {
            //     if(this.template.signatory_list[i].order == this.template_signatory.order) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'center-end',
            //             showConfirmButton: false,
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Signatory Order ${this.template_signatory.order} already exists.`,
            //         }) 
            //     }
            // }

            let row = {
                user_id: this.template_signatory.user_id,
                name: this.template_signatory.name,
                purpose: this.template_signatory.purpose,
                order: this.template_signatory.order,
                required_signature: this.template_signatory.required_signature,
                requirements: this.signatory_requirements_list.length > 0 ? true : false,
                document_signature_requirements: JSON.parse(JSON.stringify(this.signatory_requirements_list))
            };

            
            // console.log(row)
            // return;

            this.template.signatory_list.push(row);

            this.template_signatory.user_id = null;
            this.template_signatory.name = null;
            this.template_signatory.purpose = null;
            this.template_signatory.order = null;
            this.template_signatory.required_signature = true;
            this.$refs.usersModal.clear_selected = true;

            if(this.signatory_requirements_list.length > 0) this.signatory_requirements_list = []
        },
        viewRequirements: function (item, index) {
            this.signatory_requirements_key += 1
            this.selected_signatory_details = item;
            this.signatory_requirement_view = true;
            this.selected_signatory_details_index = index
            this.signatory_requirements_list = []
            for (let i = 0; i < item.document_signature_requirements.length; i++) {
                let row = {
                    id: item.document_signature_requirements[i].id,
                    requirement: item.document_signature_requirements[i].requirement,
                    description: item.document_signature_requirements[i].description,
                }
                this.signatory_requirements_list.push(row);
            }
        },
        createTemplate: function () {
            for (let i = 0; i < this.template.signatory_list.length; i++) {
                const element = this.template.signatory_list[i];
                if(!element.order) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `Signatory order field cannot be empty.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
                if(!element.purpose) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `Signatory purpose field cannot be empty.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
                if(!element.name) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `Signatory name field cannot be empty.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            }



            //Renaming key in object.
            let object = JSON.parse(JSON.stringify(this.template));
            object.document_template_signatures = object.signatory_list;
            object.document_template_category_id = object.category.id
            if(object.file_api) {
                object.document_template_file_api_id = object.file_api.id
            }

            object.document_template_shares = this.users_shared_routing_list;
            delete object.signatory_list;
            delete object.category;
            delete object.file_api
            //End Renaming


            for (let i = 0; i < object.document_template_signatures.length; i++) {
                const element = object.document_template_signatures[i];
                if(element.name.name) {
                    let user_name = element.name.name
                    let user_id = element.name.user_id

                    element.name = user_name
                    element.user_id = user_id
                }
            }


            const keys = ['name', 'order'],
            map = object.document_template_signatures.reduce((m, o) => {
                const
                    key =  keys.map(k => JSON.stringify(o[k])).join('|'),
                    [count = 0, array = []] = m.get(key) || [];
                return m.set(key, [count + 1, [...array, o]]);
            }, new Map),
            duplicates = Array
            .from(map.values(), ([count, array]) => count === 1 ? []: array)
            .flat();


            if(duplicates.length > 0) {
                return this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    // icon: 'error',
                    html: `<div class="swal2-header">
                                <ul class="swal2-progress-steps" style="display: none;"></ul>
                                <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                    <span class="swal2-x-mark">
                                        <span class="swal2-x-mark-line-left"></span>
                                        <span class="swal2-x-mark-line-right"></span>
                                    </span>
                                </div>
                                <h5>Duplicate signatory with the same order found. <br> Different order can add the same signatory in the template.</h5>
                            </div>`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }




            if(this.validateTemplate()){
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new template.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 

                        //Renaming key in object.
                        let object = JSON.parse(JSON.stringify(this.template));
                        object.document_template_signatures = object.signatory_list;
                        object.document_template_category_id = object.category.id
                        if(object.file_api) {
                            object.document_template_file_api_id = object.file_api.id
                        }

                        object.document_template_shares = this.users_shared_routing_list;
                        delete object.signatory_list;
                        delete object.category;
                        delete object.file_api
                        //End Renaming



                        for (let i = 0; i < object.document_template_signatures.length; i++) {
                            const element = object.document_template_signatures[i];
                            if(element.name.name) {
                                let user_name = element.name.name
                                let user_id = element.name.user_id

                                element.name = user_name
                                element.user_id = user_id
                            }
                        }


                        this.$Progress.start()
                        return axios.post('/drs/document-template/store', object, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.template.name} Template has been added.`,
                                })
                                this.template = this.resetForm();
                                this.users_shared_routing_list = []
                                
                                this.$v.$reset()
                                this.$v.$touch()
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            return;
        },
        resetForm: function () {
            return  {
                name: "",
                description: "",
                signatory_list: [],
                signatory_lock: false,
            }
        },
    },
    watch: {
        selected_user_for_signatory: {
            handler(newValue, oldValue) {
                this.template_signatory.name = newValue.name
                this.template_signatory.user_id = newValue.id
            },  
            deep: true
        },
        signatory_requirements_modal: function(value) {
            if(!value && this.signatory_requirement_view) {
                this.signatory_requirement_view = false
                this.signatory_requirements_list.length = 0;
            }
        },
        'template_signatory.order': function (value) {
            if(value) {
                if (!/^\d+$/.test(value)) {
                    this.template_signatory.order = null
                    this.template_signatory.order = ""
                }
            }
        },
        'template.signatory_list': function(value) {
            this.order_input = []
            for (let index = 0; index < value.length; index++) {
                this.order_input.push(parseInt(value[index].order))
            }
        },
        '$refs.usersModal.users_modal': function (value) {
        }
    }
    
}
</script>